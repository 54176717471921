export default {
  primary: "#2D3748",
  secondary: "#5C6779",
  tertiary: "#A7AAAD",
  selected: "#D3D3D3",

  appBackground: "#F3F4F6",

  darkPrimary: "#D3D3D3",
  darkSecondary: "#5C6779",
  darkTertiary: "#A7AAAD",
  darkSelected: "#5C6779",

  darkAppBackground: "#2D3748",

  blue: "#3182CE",
  green: "#38A169",
  red: "#E53E3E",
  orange: "#DD6B20",

  white: "#FFFFFF",

  "gray.200": "#E2E8F0",
  "gray.400": "#A0AEC0",
  "gray.600": "#4A5568",
  "gray.700": "#2D3748",
  "gray.800": "#1f2937",
  "gray.900": "#111827",
  "gray.950": "#030712",
}
